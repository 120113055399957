import React, { lazy, Suspense } from 'react'
import Helmet from 'react-helmet'
import { Link, useStaticQuery, graphql } from 'gatsby'
import 'react-image-lightbox/style.css'
import Layout from '../components/Layout'
import * as s from './lokasena.module.scss'
import Container from 'components/container'

const VideoPlayer = lazy(() => import('../components/player'))

export default () => {
  const isSSR = typeof window === 'undefined'

  const data = useStaticQuery(graphql`
    query VideoAndScenes {
      video: allFile(filter: { base: { eq: "lokasena.mp4" } }) {
        edges {
          node {
            publicURL
          }
        }
      }

      # scenes: allFile(filter: { extension: {}, relativeDirectory: { eq: "lokasena" } }) {
      #   edges {
      #     node {
      #       id
      #       base
      #       childImageSharp {
      #         gabstyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      #         # fluid {
      #         #   src
      #         # }
      #       }
      #     }
      #   }
      # }
    }
  `)

  // const sortNumeric = (data) => {
  //   return data.sort((a, b) => {
  //     return a.base.replace(/[^0-9]/g, '') - b.base.replace(/[^0-9]/g, '')
  //   })
  // }

  // const scenes = data.scenes.edges.map(({ node }) => node)
  // sortNumeric(scenes)

  // const images = scenes.map((img) => img.childImageSharp.fluid.src)

  return (
    <Layout>
      <Helmet title="Lokasena" />
      <div className={s.lokasena}>
        <Container>
          <Link to="/" className={s.link}>
            <svg
              width="36"
              height="11"
              viewBox="0 0 36 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 4.41755H5.76135V0L0 5.50011L5.76135 11V6.58252H36V4.41755Z"
                fill="#26e6ac"
              />
            </svg>
            <span>Forsíða</span>
          </Link>
          <h2 className={s.title}>Lokasena</h2>
          <div className={s.content}>
            <p>
              Lokasenan er 800 metra keppnishlaup á meistaramóti þar sem Gréta keppir á móti Björgu,
              Álfrúnu, Eyrúnu, Finnu og Þórunni. Lúther, Magnús og Rebekka horfa á hlaupið frá
              áhorfendasvæðinu. Myndbandið er í <i>airiel veiw</i> þar sem við sjáum stöður á
              myndavél, hlaupurum og áhorfendum. Gréta er gul og byrjar á ystu braut. Við erum
              einungis annað hvort á hlaupinu eða á fjölskyldunni, saman eða í sitthvoru lagi. Við
              klippum í svart þegar Grétu hefur tekist markmið sitt um að verða besti hlaupari
              landsins. Hljóðheimurinn er eins og í myndbandinu - lagið,{' '}
              <i>G.Q.- I Love (The Skin You‘re In)</i> er á blasti og hlaupið sjálft er hljóðlaust.
            </p>
            <p style={{ textIndent: '50px' }}>
              Ekki meitlað í stein. Ýmsir þættir eru þó staðfastir - framvindan er eins og hún er,
              Gréta byrjar öftust og hleypur síðan alla hlauparana uppi og endar fremst. Skiptingin
              á milli hlaups og fjölskyldu er eins og hún er. Annars er margt opið. Erum við í{' '}
              <i>Close up</i>, <i>Medium shot</i>, <i>Full Body shot</i>? Hvenær erum við í{' '}
              <i>One-Shot</i>, <i>Two shot</i>? Hvenær skiptum við úr <i>one</i>- yfir í <i>two</i>-
              yfir í <i>three</i>- o.s.frv.? Hvar er fókus og hvenær? Þarf hraði á myndavél að vera
              öðruvísi? Þurfum við að fjölga eða fækka skotum á ákveðnum stöðum? Þetta myndband er
              góð vísbending sem mun leiða okkur í átt að lokaútkomu.
            </p>
            <p style={{ textIndent: '50px' }}>
              Það er margt sem hægt er að gagnrýna við þetta myndband. Birtustig á milli mynda er
              stundum misjafnt (ekki að það skipti máli, en getur verið truflandi), hreyfing á
              hlaupurum/myndavél kannski óraunhæf í sumum tilvikum, en það er meira óvart frekar en
              eitthvað annað. Tilgangur og meining hvers skots fyrir sig er þó skýr. Það fylgir
              texti með á hlaupabrautinni sem lýsir því sem gerist í hverju skoti. Efst í vinstra
              horninu er svo talning á fjölda skota, sem endar í <strong>32 skotum</strong>.
            </p>
            <p style={{ textIndent: '50px' }}>
              Þetta verður allt saman skotið hrátt, engar tækni- eða tölvubrellur, kvikmyndavél í
              hæsta gæðaflokki á mikilli ferð á hlaupabraut (<i>EXT</i>.) - samferða 800 metra
              hlaupi hjá bestu hlaupurum landsins. Track á eftir, á undan, samferða hlaupurum,
              stundum push-in, stundum push-out, stundum static wide shots. Dínamískt. Spennandi.
              Grípandi.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Þetta verður besta og eftirminnilegasta sena myndarinnar.
            </p>
            <br />
            {!isSSR && (
              <Suspense fallback={<div />}>
                <VideoPlayer video={data.video.edges[0].node.publicURL} />
              </Suspense>
            )}
            {/* <div className={s.btn}>
          <button type="button" onClick={() => setIsOpen(true)}>
            Sjá öll skot hér
          </button>
        </div> */}

            {/* {isOpen && (
          <Lightbox
            mainSrc={images[idx]}
            nextSrc={images[(idx + 1) % images.length]}
            prevSrc={images[(idx + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setIdx((idx + images.length - 1) % images.length)}
            onMoveNextRequest={() => setIdx((idx + 1) % images.length)}
          />
        )} */}
          </div>
        </Container>
      </div>
    </Layout>
  )
}
